import { BoardServiceLineInterface } from 'src/interfaces/BoardServiceLineInterface';
import { MutationTree } from 'vuex';
import { BoardServiceLinesStateInterface } from '.';

const mutation: MutationTree<BoardServiceLinesStateInterface> = {

  SET_BOARD_SERVICE_LINES(
    state: BoardServiceLinesStateInterface,
    boardServiceLines: BoardServiceLineInterface[],
  ) {
    state.boardServiceLines = boardServiceLines;
  },

};

export default mutation;
