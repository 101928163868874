import { Module } from 'vuex';
import { FolioInterface } from 'src/interfaces/FolioInterface';
import { TransactionInterface } from 'src/interfaces/TransactionInterface';
import { FolioSaleLineInterface } from 'src/interfaces/FolioSaleLineInterface';
import { InvoiceInterface } from 'src/interfaces/InvoiceInterface';
import { FolioFilters } from 'src/interfaces/FolioFilters';
import { FolioMessageInterface, MessageInterface } from 'src/interfaces/FolioMessageInterface';

import { CheckinPartnerInterface } from 'src/interfaces/CheckinPartnerInterface';
import { StateInterface } from '../index';
import state from './state';
import actions from './actions';
import mutations from './mutations';

export interface FoliosStateInterface {
  currentFolio: FolioInterface | null,
  folios: FolioInterface[];
  foliosPlanning: FolioInterface[];
  transactions: TransactionInterface[];
  saleLines: FolioSaleLineInterface[];
  invoices: InvoiceInterface[];
  lastFolioFilters: FolioFilters | null;
  folioMessages: FolioMessageInterface | null;
  messages: MessageInterface | null;
  adultsInFolio: CheckinPartnerInterface[];
}

const foliosModule: Module<FoliosStateInterface, StateInterface> = {
  namespaced: true,
  actions,
  mutations,
  state,
};

export default foliosModule;
