import { ActionTree } from 'vuex';
import { api } from 'boot/axios';
import { CountryStatesInterface } from 'src/interfaces/CountryStatesInterface';
import { AxiosResponse } from 'axios';
import { StateInterface } from '../index';
import { CountryStatesStateInterface } from '.';

const actions: ActionTree<CountryStatesStateInterface, StateInterface> = {

  async fetchCountryStates(context, payload: number) {
    return api
      .get(`/countries/${payload}/country-states`)
      .then((response: AxiosResponse<CountryStatesInterface[]>) => {
        context.commit('SET_COUNTRY_STATES', response.data);
      });
  },
  removeCountryStates(context) {
    context.commit('REMOVE_COUNTRY_STATES');
  },
};

export default actions;
