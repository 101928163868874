import { store } from 'quasar/wrappers';
import { InjectionKey } from 'vue';
import {
  createStore,
  Store as VuexStore,
  useStore as vuexUseStore,
} from 'vuex';

import accountJournals, { AccountJournalsStateInterface } from './accountJournals';
import address, { AddressStateInterface } from './address';
import agencies, { AgenciesStateInterface } from './agencies';
import amenities, { AmenitiesStateInterface } from './amenities';
import amenityTypes, { AmenityTypesStateInterface } from './amenityTypes';
import availability, { AvailabilityStateInterface } from './availability';
import availabilityPlans, { AvailabilityPlanStateInterface } from './availabilityPlans';
import boardServices, { BoardServicesStateInterface } from './boardServices';
import boardServiceLines, { BoardServiceLinesStateInterface } from './boardServiceLines';
import cancelationRules, { CancelationRulesStateInterface } from './cancelationRules';
import cashRegister, { CashRegisterStateInterface } from './cashRegister';
import categories, { CategoryStateInterface } from './categories';
import checkinPartners, { CheckinPartnerStateInterface } from './checkinPartners';
import countries, { CountryStateInterface } from './countries';
import countryStates, { CountryStatesStateInterface } from './countryStates';
import dashboard, { DashboardStateInterface } from './dashboard';
import documentType, { DocumentTypeStateInterface } from './documentType';
import extraBeds, { ExtraBedsStateInterface } from './extraBeds';
import folios, { FoliosStateInterface } from './folios';
import folioServices, { FolioServicesStateInterface } from './folioServices';
import invoices, { InvoicesStateInterface } from './invoices';
import languages, { LanguageStateInterface } from './languages';
import layout, { LayoutStateInterface } from './layout';
import notifications, { NotificationsStateInterface } from './notifications';
import ocrDocument, { OcrDocumentStateInterface } from './ocrDocument';
import partners, { PartnerStateInterface } from './partners';
import paymentTerms, { PaymentTermStateInterface } from './paymentTerms';
import planning, { PlanningStateInterface } from './planning';
import prices, { PricesStateInterface } from './prices';
import pricelists, { PricelistStateInterface } from './pricelists';
import products, { ProductsStateInterface } from './products';
import properties, { PropertyStateInterface } from './properties';
import reservations, { ReservationStateInterface } from './reservations';
import reservationLines, { ReservationLinesStateInterface } from './reservationLines';
import rooms, { RoomsStateInterface } from './rooms';
import roomClosureReasons, { RoomClosureReasonsStateInterface } from './roomClosureReasons';
import roomTypes, { RoomTypeStateInterface } from './roomTypes';
import roomTypeClasses, { RoomTypeClassStateInterface } from './roomTypeClasses';
import saleChannels, { SaleChannelsStateInterface } from './saleChannels';
import services, { ServiceStateInterface } from './services';
import transactions, { TransactionsStateInterface } from './transactions';
import ubications, { UbicationStateInterface } from './ubications';
import user, { UserStateInterface } from './user';
import users, { UsersStateInterface } from './users';
import precheckin, { PrecheckinStateInterface } from './precheckin';

export interface StateInterface {
  accountJournals: AccountJournalsStateInterface;
  address: AddressStateInterface,
  agencies: AgenciesStateInterface;
  amenities: AmenitiesStateInterface,
  amenityTypes: AmenityTypesStateInterface,
  availability: AvailabilityStateInterface,
  availabilityPlans: AvailabilityPlanStateInterface,
  boardServices: BoardServicesStateInterface;
  boardServiceLines: BoardServiceLinesStateInterface;
  cancelationRules: CancelationRulesStateInterface;
  cashRegister: CashRegisterStateInterface,
  categories: CategoryStateInterface;
  checkinPartners: CheckinPartnerStateInterface;
  countries: CountryStateInterface;
  countryStates: CountryStatesStateInterface;
  dashboard: DashboardStateInterface;
  documentType: DocumentTypeStateInterface;
  extraBeds: ExtraBedsStateInterface;
  folios: FoliosStateInterface;
  folioServices: FolioServicesStateInterface,
  invoices: InvoicesStateInterface,
  languages: LanguageStateInterface;
  layout: LayoutStateInterface;
  notifications: NotificationsStateInterface,
  ocrDocument: OcrDocumentStateInterface;
  partners: PartnerStateInterface;
  paymentTerms: PaymentTermStateInterface;
  planning: PlanningStateInterface;
  prices: PricesStateInterface;
  pricelists: PricelistStateInterface;
  products: ProductsStateInterface;
  properties: PropertyStateInterface;
  reservations: ReservationStateInterface;
  reservationLines: ReservationLinesStateInterface;
  reservationState: ReservationStateInterface;
  rooms: RoomsStateInterface;
  roomClosureReasons: RoomClosureReasonsStateInterface;
  roomTypes: RoomTypeStateInterface;
  roomTypeClasses: RoomTypeClassStateInterface;
  saleChannels: SaleChannelsStateInterface;
  services: ServiceStateInterface;
  transactions: TransactionsStateInterface;
  ubications: UbicationStateInterface;
  user: UserStateInterface;
  users: UsersStateInterface;
  precheckin: PrecheckinStateInterface;
}

// provide typings for `this.$store`
declare module '@vue/runtime-core' {
interface ComponentCustomProperties {
    $store: VuexStore<StateInterface>

  }
}

// provide typings for `useStore` helper
export const storeKey: InjectionKey<VuexStore<StateInterface>> = Symbol('vuex-key');

export default store((/* { ssrContext } */) => {
  const Store = createStore<StateInterface>({
    modules: {
      accountJournals,
      address,
      agencies,
      amenities,
      amenityTypes,
      availability,
      availabilityPlans,
      boardServices,
      boardServiceLines,
      cancelationRules,
      cashRegister,
      categories,
      checkinPartners,
      countries,
      countryStates,
      dashboard,
      documentType,
      extraBeds,
      folios,
      folioServices,
      invoices,
      languages,
      layout,
      notifications,
      ocrDocument,
      partners,
      paymentTerms,
      planning,
      precheckin,
      prices,
      pricelists,
      products,
      properties,
      reservations,
      reservationLines,
      rooms,
      roomClosureReasons,
      roomTypes,
      roomTypeClasses,
      saleChannels,
      services,
      transactions,
      ubications,
      user,
      users,
    },

    // enable strict mode (adds overhead!)
    // for dev mode and --debug builds only
    // strict: !!process.env.DEBUGGING
  });

  return Store;
});

export function useStore() {
  return vuexUseStore(storeKey);
}
