import { ActionTree } from 'vuex';

import { api } from 'boot/axios';
import { AxiosResponse } from 'axios';
import { StateInterface } from '../index';
import { NotificationsStateInterface } from '.';

const actions: ActionTree<NotificationsStateInterface, StateInterface> = {

  async fetchNotificationsReservationsToAssign(context, pmsPropertyId: number) {
    return api
      .get(`/notifications/reservations-to-assign?pmsPropertyId=${pmsPropertyId}`)
      .then((response: AxiosResponse<{numReservationsToAssign: number}>) => {
        context.commit('SET_RESERVATIONS_TO_ASSIGN', response.data.numReservationsToAssign);
      });
  },
};

export default actions;
