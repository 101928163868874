import { ActionTree } from 'vuex';
import { AxiosResponse } from 'axios';
import { BoardServiceLineInterface } from 'src/interfaces/BoardServiceLineInterface';
import { api } from '../../boot/axios';

import { StateInterface } from '../index';
import { BoardServiceLinesStateInterface } from '.';

const actions: ActionTree<BoardServiceLinesStateInterface, StateInterface> = {
  async fetchBoardServiceLines(
    context, payload: { pmsPropertyId: number; boardServiceId: number },
  ) {
    return api
      .get(`/board-services/${payload.boardServiceId}/lines?pmsPropertyId=${payload.pmsPropertyId}`)
      .then((response: AxiosResponse<BoardServiceLineInterface[]>) => {
        context.commit('SET_BOARD_SERVICE_LINES', response.data);
      });
  },
};

export default actions;
