import { Module } from 'vuex';
import { BoardServiceLineInterface } from 'src/interfaces/BoardServiceLineInterface';
import { StateInterface } from '../index';
import state from './state';
import actions from './actions';
import mutations from './mutations';

export interface BoardServiceLinesStateInterface {
  boardServiceLines: BoardServiceLineInterface[];
}

const boardServiceLinesModule: Module<BoardServiceLinesStateInterface, StateInterface> = {
  namespaced: true,
  actions,
  mutations,
  state,
};

export default boardServiceLinesModule;
