import { ServiceStateInterface } from '.';

function state(): ServiceStateInterface {
  return {
    services: [],
    folioServices: [],
  };
}

export default state;
