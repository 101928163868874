import { RouteRecordRaw } from 'vue-router';

const routes: RouteRecordRaw[] = [
  // -----------------------------------------
  // login
  {
    path: '/login/:pmsPropertyId(\\d+)?',
    component: () => import('layouts/LoginLayout.vue'),
    children: [{ name: 'login', path: '', component: () => import('src/pages/LoginPage.vue') },
    ],
  },
  // -----------------------------------------
  // reset password
  {
    path: '/reset-password/:token([^/]+)?',
    component: () => import('layouts/LoginLayout.vue'),
    children: [{ name: 'resetPassword', path: '', component: () => import('src/pages/ResetPasswordPage.vue') },
    ],
  },
  // -----------------------------------------
  // dashboard (initial page)
  {
    path: '/:pmsPropertyId(\\d+)?',
    component: () => import('src/layouts/MainLayout.vue'),
    children: [{ name: 'dashboard', path: '', component: () => import('src/pages/DashboardPage.vue') }],
  },
  // planning
  {
    path: '/planning/:pmsPropertyId(\\d+)?',
    component: () => import('layouts/MainLayout.vue'),
    children: [{ name: 'planning', path: '', component: () => import('src/pages/PlanningPage.vue') },
    ],
  },
  // -----------------------------------------
  // transactions
  {
    path: '/transactions/:pmsPropertyId(\\d+)?',
    component: () => import('layouts/MainLayout.vue'),
    children: [{ name: 'transactions', path: '', component: () => import('src/pages/TransactionsPage.vue') }],
  },
  // -----------------------------------------
  // invoices
  {
    path: '/invoices/:pmsPropertyId(\\d+)?',
    component: () => import('layouts/MainLayout.vue'),
    children: [{ name: 'invoices', path: '', component: () => import('src/pages/InvoicesPage.vue') }],
  },

  // partners
  {
    path: '/partners/:pmsPropertyId(\\d+)?',
    component: () => import('src/layouts/MainLayout.vue'),
    children: [{ name: 'partners', path: '', component: () => import('src/pages/PartnerListPage.vue') }],
  },
  // folio precheckin
  {
    path: '/:folioId([^/]+)/precheckin/:folioToken([^/]+)',
    component: () => import('src/layouts/PublicLayout.vue'),
    children: [{ name: 'folio-precheckin', path: '', component: () => import('src/pages/PublicFolioPage.vue') }],
  },
  // reservation precheckin
  {
    path: '/:reservationId([^/]+)/precheckin-reservation/:reservationToken([^/]+)',
    component: () => import('src/layouts/PublicLayout.vue'),
    children: [{ name: 'reservation-precheckin', path: '', component: () => import('src/pages/PublicReservationPage.vue') }],
  },
  // test
  {
    path: '/test',
    component: () => import('src/layouts/MainLayout.vue'),
    children: [{ name: 'test', path: '', component: () => import('src/pages/TestPage.vue') }],
  },
  // 404
  { path: '/404', component: () => import('src/pages/Error404Page.vue') },
  // Always leave this as last one,
  // but you can also remove it
  { path: '/:catchAll(.*)*', component: () => import('src/pages/Error404Page.vue') },
];

export default routes;
